import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '134, 19, 79',
		'primary-dark': '85, 2, 39',
		'accent': '195, 25, 99',
		'accent-plus': '255, 255, 255',
	},
});
