import { russianPaymentCardsNarrowedSet } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'ул. Кирова, 51 «Б»',
		place: null,
		phoneNumbers: ['+7 (86354) 5-89-89'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/illyzion_kino',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://itunes.apple.com/ru/app/иллюзион-батайск-билеты-в-кино/id1210022734?mt=8',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.illusion',
		},
		payment: {
			active: true,
			card: russianPaymentCardsNarrowedSet,
		},
		maps: 'https://yandex.ru/maps/-/CBQbIUhTGB',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};
